.knowMyWork-item-title-parag {
  /* background-color: red; */
  margin-left: 20px;
}

.kmw-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: red; */
}

.workImage {
  flex-shrink: 0;
  object-fit: contain;
  width: auto;
  /* height: 80px; */
}



.knowMyWork-contentBody {
  display: flex;
  /* gap: 20px; */
  /* background-color: green; */
}

.knowMyWork-contentItem {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 0;
  margin-bottom: 2rem;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 3rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* General container settings */
.knowMyWork-container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  justify-content: center;
  background-color: #f5f5f5; /* Light background to make content pop */
}

.knowMyWork-category-title {
  font-weight: bold;
  font-style: italic;
  margin-bottom: 20px;
}

/* Typography improvements for names and titles */
.knowMyWork-my-name-title,
.knowMyWork-title-title {
  text-align: center;
  color: #212529; /* Dark grey for better readability */
}

.knowMyWork-my-name-title {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 40px; /* Slightly larger for emphasis */
  font-weight: 700; /* Bold font weight for name */
}

.knowMyWork-title-title {
  margin-top: 5px;
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: 400; /* Regular font weight for subtitle */
}

/* Intro link styling */
.knowMyWork-intro {
  text-align: right;
  margin: 20px 0;
}

.knowMyWork-intro a {
  padding: 8px 16px;
  background-color: #007bff;
  color: #ffffff;
  border-radius: 0; /* Sharp edges for a modern look */
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.knowMyWork-intro a:hover {
  background-color: #0056b3;
  transform: translateY(-2px); /* Slight raise effect on hover */
}

.knowMyWork-item-description {
  margin-left: 0; /* Adjusted for general use */
  flex: 1; /* Take remaining space */
}



/* Other styles remain unchanged */

/* Responsive Design Adjustments */
@media (max-width: 768px) {
  .knowMyWork-container,
  .knowMyWork-contentBody,
  .knowMyWork-intro {
    flex-direction: column;
    text-align: center;
  }

  .knowMyWork-intro {
    margin: 20px 0; /* Ensure spacing is consistent */
    align-self: center; /* Center the link */
  }

  .knowMyWork-contentBody,
  .kmw-1 {
    flex-direction: column;
    align-items: center; /* Center align items for a cleaner look */
  }

  .knowMyWork-item-title-parag {
    margin-left: 0; /* Ensure title is centered above image */
  }

  .workImage {
    margin-bottom: 15px; /* Space between image and description */
  }
}
