.typesOfSoftware-container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  justify-content: center;
}

.typesOfSoftware-contentItem {
  margin-bottom: 2rem;
  padding: 1rem;
  /* Ensure it doesn't stretch too wide on larger screens */
  max-width: 100%;
}

.contentBody {
  display: flex; 
  flex-direction: row;
  align-items: flex-start;
  margin-top: 1rem; 
  /* background-color: red; */
}

.typesOfSoftware h2,
.typesOfSoftware p {
  margin-right: 1rem; /* Adjust spacing as needed */
}

.softwareImage {
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin-right: 1rem;
  flex-shrink: 0;
}

/* Consider adding responsive adjustments */
@media (max-width: 768px) {
  .contentBody {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }

  .softwareImage {
    margin-right: 0; /* Adjust or remove right margin on smaller screens */
    margin-bottom: 1rem; /* Adds space below the image */
  }
}
