@font-face {
  font-family: "Cascadia Mono";
  src: url("../../public/fonts/CascadiaMono.ttf") format("truetype");
}

.header-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  font-size: 22px;
  background-color: #f5f5f5; /* Light gray background */
}

.header-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-brand {
  font-family: "Cascadia Mono", monospace;
  font-size: 24px;
  font-weight: bold;
  color: rgba(22, 22, 23, 0.8);
  text-decoration: none;
  padding: 8px 10px;
  white-space: nowrap;
}

.navbar {
  display: flex;
  align-items: center;
}

ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

li {
  margin-left: 20px;
}

.nav-link {
  font-size: 18px;
  font-weight: 500;
  color: rgba(22, 22, 23, 0.8);
  text-decoration: none;
  padding: 8px 10px;
  border-radius: 5px;
  font-family: "Cascadia Mono", monospace;
  transition: background-color 0.3s, color 0.3s;
}

.nav-link:hover,
.nav-link:focus {
  background-color: rgba(22, 22, 23, 0.1);
  color: #000;
}

.nav-link:visited {
  color: rgba(22, 22, 23, 0.8);
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .header-navbar {
    padding: 10px 20px;
    font-size: 18px;
  }

  .header-container {
    flex-direction: column;
    align-items: center;
  }

  .navbar-brand {
    font-size: 20px;
    margin-bottom: 10px; /* Adjusted for mobile */
  }

  ul {
    flex-direction: column;
    align-items: center;
  }

  li {
    margin: 5px 0; /* Adjusted spacing for mobile */
  }

  .nav-link {
    font-size: 16px;
  }

  .clipboard-button {
    cursor: pointer;
    background-color: transparent;
    border: none; /* Explicitly removing any border */
    font-size: 16px;
    margin-left: 8px;
    padding: 0; /* Removing padding to align with text style */
  }

  .clipboard-button:hover,
  .clipboard-button:focus {
    background-color: transparent; /* Maintain a consistent background on hover/focus */
    color: #007bff; /* Optional: Change color on hover/focus for better visibility */
  }
}
