:root {
  --color-1: #0c79c2;
  --color-2: #156cd6;
  --color-3: #f9f9f9;
  --color-4: #ffffff;
  --color-5: #333333;
  --color-6: #fce8eb;
  --color-7: #666666;
  --color-8: #ebebeb;
}

.init-page-v1-pagecontainer {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 50px;
  justify-content: center;
}

.init-page-v1-below-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.init-page-v1-row {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.main-content, .sidebar {
  /* Adjust these as necessary */
}

.highlight {
  color: var(--color-2);
}

.social-links {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.social-links li {
  display: inline-block;
  margin-right: 10px;
}

.buttons-separation{
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  flex-wrap: wrap; /* Allow buttons to wrap on smaller screens */
  gap: 20px; /* Optional: Adds space between buttons when they wrap */
}

.works-button {
  background-color: var(--color-2);
  color: var(--color-4);
  text-decoration: none;
  padding: 10px 20px;
  margin-top: 20px;
  display: inline-flex; /* Use inline-flex for button alignment */
  align-items: center; /* Align items vertically */
  justify-content: center; /* Center content */
  height: auto; /* Adjust height for content */
  width: auto; /* Adjust width for content */
  font-size: 26px;
}

.i-help-text {
  display: block;
  width: 700px;
  margin-top: 30px;
  /* font-size: 20px; */
}



/* Existing styles for other elements remain unchanged */

/* Media Queries */
@media (max-width: 768px) {
  .init-page-v1-row {
    flex-direction: column;
  }

  .main-content, .sidebar {
    width: 100%;
    padding: 0 10px;
  }

  /* Adjust the order and center the image for narrow screens */
  .sidebar {
    order: -1; /* Moves the sidebar (image) above the text */
    display: flex;
    justify-content: center; /* Center the image horizontally */
  }

  .buttons-separation {
    flex-direction: column;
    align-items: center;
  }

  .works-button {
    width: 80%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .i-help-text {
    width: auto;
    margin-top: 30px;
    font-size: 18px;
  }
}



