.footer {
    background-color: #f5f5f5;
    color: #333;
    text-align: center;
    padding: 20px 0;
    font-size: 14px;
    border-top: 1px solid #e4e4e4;
}

.footer-content p {
    margin: 5px 0;
}

.footer-link {
    color: inherit; /* Inherit the font color from the parent */
    text-decoration: none; /* Remove underline */
}

.footer-link:hover {
    text-decoration: underline; /* Optional: add underline on hover for accessibility */
}

/* Add responsiveness if needed */
@media (max-width: 768px) {
    .footer {
        padding: 20px 10px;
    }

    .footer-content p {
        font-size: 12px;
    }
}
